export const PM_TYPE_BANK_ACCOUNT = 'bank_account';
export const PM_TYPE_CREDIT_CARD = 'credit_card';
export const STATUS_AWAITING_SIGNATURE = "awaiting_signature";
export const STATUS_SIGNATURE_DELINED = "signature_declined";
export const STATUS_PENDING = "pending";
export const STATUS_ACTIVE = "active";
export const STATUS_DISABLED = "disabled";
export const PAYMENT_METHODS_STATUS_LIST = {
    '': '',
    [STATUS_ACTIVE]: "active",
    [STATUS_DISABLED]: "disabled",
    [STATUS_PENDING]: "pending",
    [STATUS_SIGNATURE_DELINED]: "signature_declined",
    [STATUS_AWAITING_SIGNATURE]: "awaiting_signature",
};
export const PAYMENT_METHODS_TYPES = {
    '': '',
    [PM_TYPE_BANK_ACCOUNT]: "bank_account",
    [PM_TYPE_CREDIT_CARD]: "credit_card",
};
export const STATUS_LIST_ARRAY = [
    STATUS_ACTIVE,
    STATUS_DISABLED,
    STATUS_PENDING,
    STATUS_SIGNATURE_DELINED,
    STATUS_AWAITING_SIGNATURE,
];