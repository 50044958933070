<template>
  <div class="">
    <el-form
      class="add-form payment-method-form"
      @submit.prevent="checkPaymentMethodInformation"
      @keydown.enter.prevent="() => {}"
      :model="paymentMethod"
      label-position="top"
      ref="paymentMethodForm"
    >
      <div class="form-group-wrapper overflow-hidden">

        <div class="account-owner w-100" v-if="!holderId">
          <div class="row w-100">
            <div class="col-lg-6">
                <base-input
                v-if="
                  $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
                "
                  :label="$t('COMMON.ORGANIZATION')"
                  :placeholder="$t('COMMON.ORGANIZATION')"
                >
                  <organization-selector
                    :filterable="true"
                    :showAll="false"
                    :allowNone="true"
                    @organizationChanged="
                      (organizationId) => {
                        this.selectedOrganizationId = organizationId;
                        onOrganizationChanged();
                        onFormChanged();
                      }
                    "
                  />
                </base-input>
            </div>
                <div class="col-lg-6">
                <base-input
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
                  :placeholder="`${$t('COMMON.LOCATION')}`"
                  :label="$t('COMMON.LOCATION')"
                >
                  <location-selector
                    :filterable="true"
                    :showAll="false"
                    :allowNone="true"
                    :organization="selectedOrganizationId"
                    @locationChanged="
                      (locationId) => {
                        this.selectedLocationId = locationId
                        onLocationChanged();
                        onFormChanged();
                      }
                    "
                  />
                </base-input>
              </div>
          </div>
        </div>
      
        <div class="account  mb-3">
        <h3 class="text-danger">{{ $t("PAYMENT_METHODS.PAYMENT_METHOD_ACCOUNT") }}</h3>
        <el-divider />
        <div class="row">
          <div class="col-lg-6">
            <el-form-item
              :label="$t('PAYMENT_METHODS.bank_name')"
              prop="details.bank_name"
              :rules="[
                { required: true, message: this.$t('PAYMENT_METHODS.bank_name_required') },
              ]"
            >
              <el-input
                v-model="paymentMethod.details.bank_name"
                :placeholder="$t('PAYMENT_METHODS.bank_name')"
              />
            </el-form-item>
          </div>
          <div class="col-lg-6">
            <el-form-item
              :label="$t('PAYMENT_METHODS.account_number')"
              prop="details.account_number"
              :rules="[
                {
                  required: true,
                  message: this.$t('PAYMENT_METHODS.account_number_required'),
                },
              ]"
            >
              <el-input
                type="number"
                v-model="paymentMethod.details.account_number"
                :placeholder="$t('PAYMENT_METHODS.account_number')"
              />
            </el-form-item>
          </div>
          <div class="col-lg-6">
            <el-form-item
              :label="$t('PAYMENT_METHODS.transit_number')"
              prop="details.transit_number"
              :rules="[
                { required: true, message: this.$t('PAYMENT_METHODS.transit_number_required') },
              ]"
            >
              <el-input
                type="number"
                v-model="paymentMethod.details.transit_number"
                :placeholder="$t('PAYMENT_METHODS.transit_number')"
              />
            </el-form-item>
          </div>
          <div class="col-lg-3">
            <el-form-item
              :label="$t('PAYMENT_METHODS.branch_code')"
              prop="details.branch_code"
              :rules="[
                {
                  required: true,
                  message: this.$t('PAYMENT_METHODS.branch_code_required'),
                },
              ]"
            >
              <el-input
                v-model="paymentMethod.details.branch_code"
                :placeholder="$t('PAYMENT_METHODS.branch_code')"
              />
            </el-form-item>
          </div>
          <div class="col-lg-3">
            <el-form-item
              :label="$t('PAYMENT_METHODS.bank_acronym')"
              prop="details.bank_acronym"
              :rules="[
                {
                  required: true,
                  message: this.$t('PAYMENT_METHODS.bank_acronym_required'),
                },
              ]"
            >
              <el-input
                v-model="paymentMethod.details.bank_acronym"
                :placeholder="$t('PAYMENT_METHODS.bank_acronym')"
              />
            </el-form-item>
          </div>
        </div>
      </div>

      
      <div class="holder mb-3">
        <h3 class="text-danger">{{ $t("PAYMENT_METHODS.PAYMENT_METHOD_HOLDER") }}</h3>
        <el-divider />
        <div class="row">
          <div class="col-lg-6">
            <el-form-item
              :label="$t('PAYMENT_METHODS.firstname')"
              prop="details.holder.firstname"
              :rules="[
                {
                  required: true,
                  message: this.$t('PAYMENT_METHODS.firstname_required'),
                },
              ]"
            >
              <el-input
                v-model="paymentMethod.details.holder.firstname"
                :placeholder="$t('PAYMENT_METHODS.firstname')"
              />
            </el-form-item>
          </div>
          <div class="col-lg-6">
            <el-form-item
              :label="$t('PAYMENT_METHODS.lastname')"
              prop="details.holder.lastname"
              :rules="[
                {
                  required: true,
                  message: this.$t('PAYMENT_METHODS.lastname_required'),
                },
              ]"
            >
              <el-input
                v-model="paymentMethod.details.holder.lastname"
                :placeholder="$t('PAYMENT_METHODS.lastname')"
              />
            </el-form-item>
          </div>

          <div class="col-lg-6">
            <el-form-item
              :label="$t('PAYMENT_METHODS.email')"
              prop="details.holder.email"
              :rules="[
                { required: true, message: $t('PAYMENT_METHODS.email_required') },
                { type: 'email', message: $t('PAYMENT_METHODS.email_correct') },
              ]"
            >
              <el-input
                type="email"
                v-model="paymentMethod.details.holder.email"
                :placeholder="$t('PAYMENT_METHODS.email')"
              />
            </el-form-item>
          </div>
          <div class="col-lg-6">
            <el-form-item :label="$t('PAYMENT_METHODS.phone')">
              <el-input
                v-model="paymentMethod.details.holder.phone"
                :placeholder="$t('PAYMENT_METHODS.phone')"
              />
            </el-form-item>
          </div>
          <div class="col-lg-12">
            <el-form-item :label="$t('PAYMENT_METHODS.address')">
              <el-input
                v-model="paymentMethod.details.holder.address"
                :placeholder="$t('PAYMENT_METHODS.address')"
              />
            </el-form-item>
          </div>



        </div>
      </div>

      <!-- Section Signataires -->
      <div class="signers w-100 mb-3">
          <div class="row">
          <div class="col-md-4 text-start">
            <h3>{{ $t("PAYMENT_METHODS.PAYMENT_METHOD_SIGNERS") }}</h3>
          </div>
          <div class="col-md-8 d-flex justify-content-end">
          <!-- Bouton pour ajouter un signataire -->
          <el-button
            class="my-3"
            type="secondary"
            icon="el-icon-plus"
            @click="addSigner"
            style="margin-top: 10px"
          >
            {{ $t("PAYMENT_METHODS.ADD_SIGNER") }}
          </el-button>
        </div>
        <el-divider />
      </div>

        <div class="add-signer mb-3" v-for="(signer, index) in paymentMethod.signers" :key="index">
          <div class="delete-signer d-flex justify-content-end">
            <el-button type="danger" class="very-small" icon="el-icon-delete" circle @click="removeSigner(index)">x</el-button>
          </div>
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                  :label="$t('PAYMENT_METHODS.firstname')"
                  :prop="'signers.' + index + '.firstname'"
                  :rules="[
                    {
                      required: true,
                      message: $t('PAYMENT_METHODS.firstname_required'),
                    },
                  ]"
                >
                  <el-input
                    v-model="signer.firstname"
                    :placeholder="$t('PAYMENT_METHODS.firstname')"
                  />
                </el-form-item>
            </div>
            <div class="col-md-6">
                <el-form-item
                  :label="$t('PAYMENT_METHODS.lastname')"
                  :prop="'signers.' + index + '.lastname'"
                  :rules="[
                    { required: true, message: $t('PAYMENT_METHODS.lastname_required') },
                  ]"
                >
                  <el-input
                    v-model="signer.lastname"
                    :placeholder="$t('PAYMENT_METHODS.lastname')"
                  />
                </el-form-item>
            </div>
            <div class="col-md-6">
                <el-form-item
                  :label="$t('PAYMENT_METHODS.email')"
                  :prop="'signers.' + index + '.email'"
                  :rules="[
                    { required: true, message: $t('PAYMENT_METHODS.email_required') },
                    { type: 'email', message: $t('PAYMENT_METHODS.email_correct') },
                  ]"
                >
                  <el-input
                    type="email"
                    v-model="signer.email"
                    :placeholder="$t('PAYMENT_METHODS.email')"
                  />
                </el-form-item>
            </div>
            <div class="col-md-6">
                <el-form-item
                  :prop="'signers.' + index + '.address'"
                  :label="$t('PAYMENT_METHODS.address')"
                >
                  <el-input
                    v-model="signer.address"
                    :placeholder="$t('PAYMENT_METHODS.address')"
                  />
                </el-form-item>
            </div>
          </div>
        </div>
      </div>


        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            @click.prevent="checkPaymentMethodInformation"
            :disabled="loading || processing"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
            {{
              paymentMethod.id
                ? $t("PAYMENT_METHODS.EDIT_PAYMENT_METHOD")
                : $t("PAYMENT_METHODS.ADD_PAYMENT_METHOD")
            }}
          </base-button>
        </div>
      </div>
    </el-form>

    <modal
      :show="showPaymentMethodCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("PAYMENT_METHODS.SAME_PAYMENT_METHODS", {
                name: paymentMethod.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="paymentMethods"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ `${row.name}` }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('COMMON.EXCERPT')" min-width="300">
                <template v-slot="{ row }">
                  {{ row.excerpt ?? "" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="togglePaymentMethodCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Option,
  Select,
  Table,
  TableColumn,
  Form,
  FormItem,
  Button,
  Divider
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { CATEGORY_FEE } from "@/constants/charges";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
export default {
  layout: "DashboardLayout",

  components: {
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    OrganizationSelector,
    LocationSelector
  },

  mixins: [formMixin],

  props: [
    "paymentMethodData",
    "formErrors",
    "loading",
    "hideSubmit",
    "onlyCharges",
    "holderType",
    "holderId"
  ],
  data() {
    let paymentMethodData = { ...this.paymentMethodData };
    return {
      selectedOrganizationId:null,
      selectedLocationId:null,
      paymentMethod: paymentMethodData,
      paymentMethods: [],
      total: 0,
      showModal: false,
      showPaymentMethodCheckModal: false,
      processing: false,
      categoryFee: CATEGORY_FEE,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    onOrganizationChanged(){
      this.selectedLocationId = null;
    },
    onLocationChanged(){
      this.selectedOrganizationId = null;
    },

    feeSelected(selected) {
      this.paymentMethod.charges = [...selected].map((item) => ({
        type: "charges",
        id: item,
      }));
    },
    async handleSubmit() {
      const checkUniquePaymentMethod =
        await this.checkPaymentMethodUniqueInformation();
      if (checkUniquePaymentMethod) {
        await this.sendInformation();
      } else {
        this.showPaymentMethodCheckModal = true;
      }
    },

    async sendInformation() {
      if(this.holderType){
        this.paymentMethod.holder_type = this.holderType;
        this.paymentMethod.holder_id = this.holderId;
      }else{
        if(this.selectedOrganizationId){
          this.paymentMethod.holder_type = "Organization";
          this.paymentMethod.holder_id = this.selectedOrganizationId;
        }else if(this.selectedLocationId){
          this.paymentMethod.holder_type = "Location";
          this.paymentMethod.holder_id = this.selectedLocationId;
        }
      }
      let paymentMethodData = cloneDeep(this.paymentMethod);
      this.$emit("paymentMethodSubmitted", paymentMethodData);
    },

    cancelEdition() {
      this.$emit("cancelEdition");
    },

    getPackageIds(charges) {
      if (charges && charges.length) {
        return charges.map((charge) => charge.id);
      }
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    // Méthode pour ajouter un signataire
    addSigner() {
      this.paymentMethod.signers.push({
        firstname: "",
        lastname: "",
        address: "",
        phone: "",
        email: "",
      });
    },
    // Méthode pour supprimer un signataire
    removeSigner(index) {
      this.paymentMethod.signers.splice(index, 1);
    },

    async getList() {
      if (!this.paymentMethod?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.paymentMethod?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };
        await this.$store.dispatch("paymentMethods/list", params);
        this.paymentMethods = this.$store.getters["paymentMethods/list"];
        this.total = this.$store.getters["paymentMethods/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkPaymentMethodUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.paymentMethod?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.togglePaymentMethodCheckModalState();
      await this.sendInformation();
    },

    async togglePaymentMethodCheckModalState() {
      this.showPaymentMethodCheckModal = !this.showPaymentMethodCheckModal;
    },

    checkPaymentMethodInformation() {
      this.$refs["paymentMethodForm"].validate(async (valid) => {
        if (valid) {
            this.handleSubmit();
        }
      });
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    paymentMethodData(paymentMethodData) {
      if (paymentMethodData) {
        this.paymentMethod = {
          ...this.paymentMethod,
          ...cloneDeep(paymentMethodData),
        };
      }
    },
  },
};
</script>

<style>
.add-form .btn-cancel {
  font-size: 14px;
  min-height: 40px;
  border-radius: 8px;
  padding: 0 15px;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
}

.add-form .el-input__inner,
.add-form .el-textarea {
  background-color: transparent;
  border-radius: 8px;
  color: #000;
}
.form-group-wrapper-item .el-select {
  width: 100%;
}
.form-group-wrapper-item label {
  margin-bottom: 0.1rem !important;
}
.form-group-wrapper-item .el-form-item__label {
  padding: 0 0 1px !important;
}
</style>
